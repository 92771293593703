import React from "react"
import './railroad.css'

function Railroad({ diagram, interactive, onClickNode }) {
  // TODO: set tabIndex on the pieces to make accessible?
  return (
    <div
      onClick={(e) => {
        const possiblyClickedTexts = Array.from(e.target.parentElement.querySelectorAll('text')).map(t => t.innerHTML);
        // Clicking too high up the chain allows anything
        if (possiblyClickedTexts.length === 1) {
          if (onClickNode) {
            onClickNode(possiblyClickedTexts[0])
          }
        }
      }}
      className={"railroad-diagram-container" + (interactive ? ' railroad-diagram-interactive' : '')}
      dangerouslySetInnerHTML={{
        __html: diagram.toString()
      }}>
    </div>
  );
}

export default Railroad