import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Choice(
    0,
    new Terminal('xp'),
    new Terminal('experience'),
  ),
  new Choice(
    0,
    new Sequence(
      'add',
      new NonTerminal('target'),
      new NonTerminal('amount'),
      new Optional(
        new Choice(
          0,
          'levels',
          'points',
        ),
      ),
    ),
    new Sequence(
      'set',
      new NonTerminal('target'),
      new NonTerminal('amount'),
      new Optional(
        new Choice(
          0,
          'levels',
          'points',
        ),
      ),
    ),
    new Sequence(
      'query',
      new NonTerminal('target'),
      new Choice(
        0,
        'levels',
        'points',
      ),
    ),
  ),
)

export const RailBedrock = new Diagram(
  new Choice(
    0,
    new Terminal('xp'),
  ),
  new Choice(
    0,
    new NonTerminal('amount'),
    new Optional('L'),
  ),
  new Optional(
    new NonTerminal('player')
  )
)
