import { Link } from "gatsby";
import Railroad from "../../../../src/components/railroad";
import RailroadSet from "../../../../src/components/railroadSet";
import { Rail as BanRail } from "../../../../src/commands/ban.ts";
import { TargetSelectorRailroad } from "../../../../src/commands/_target-selector.tsx";
import { Diagram, ComplexDiagram, MultipleChoice, OneOrMore, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";
import * as React from 'react';
export default {
  Link,
  Railroad,
  RailroadSet,
  BanRail,
  TargetSelectorRailroad,
  Diagram,
  ComplexDiagram,
  MultipleChoice,
  OneOrMore,
  Comment,
  Optional,
  Choice,
  Terminal,
  Group,
  Sequence,
  NonTerminal,
  Stack,
  React
};