import { Diagram, ComplexDiagram, Comment, Optional, HorizontalChoice, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('gamerule'),
  new HorizontalChoice(
    new Choice(0,
      'announceAdvancements',
      'commandBlockOutput',
      'disableElytraMovementCheck',
      'disableRaids',
      'doDaylightCycle',
      'doEntityDrops',
      'doFireTick',
      'doInsomnia',
      'doImmediateRespawn',
      'doLimitedCrafting',
      'doMobLoot',
    ),
    new Choice(0,
      'doMobSpawning',
      'doPatrolSpawning',
      'doTileDrops',
      'doTraderSpawning',
      'doWeatherCycle',
      'drowningDamage',
      'fallDamage',
      'fireDamage',
      'forgiveDeadPlayers',
      'keepInventory',
      'logAdminCommands',
    ),
    new Choice(0,
      'maxCommandChainLength',
      'maxEntityCramming',
      'mobGriefing',
      'naturalRegeneration',
      'randomTickSpeed',
      'reducedDebugInfo',
      'sendCommandFeedback',
      'showDeathMessages',
      'spawnRadius',
      'spectatorsGenerateChunks',
      'universalAnger',
    ),
  ),
  new Optional(new NonTerminal('value')),
)

export const RailBedrock = new Diagram(
  new Terminal('gamerule'),
  new HorizontalChoice(
    new Choice(0,
      'commandBlocksEnabled',
      'commandBlockOutput',
      'doDaylightCycle',
      'doEntityDrops',
      'doFireTick',
      'doInsomnia',
      'doImmediateRespawn',

    ),
    new Choice(0,
      'doMobLoot',
      'doMobSpawning',
      'doTileDrops',
      'doWeatherCycle',
      'drowningDamage',
      'fallDamage',
      'fireDamage',
    ),
    new Choice(0,
      'keepInventory',
      'maxCommandChainLength',
      'mobGriefing',
      'naturalRegeneration',
      'pvp',
      'randomTickSpeed',
    ),
  ),
  new Optional(new NonTerminal('value')),
)
