import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('whitelist'),
  new Choice(0,
    new Sequence(
      'add',
      new NonTerminal('targets'),
    ),
    new Sequence(
      'remove',
      new NonTerminal('targets'),
    ),
    'list',
    'on',
    'off',
    'reload',
  ),
)
