import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('trigger'),
  new NonTerminal('objective'),
  new Choice(0,
    'add',
    'set',
  ),
  new NonTerminal('value'),
)
