import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('bossbar'),
  new Choice(
    0,
    new Sequence(
      'add',
      new NonTerminal('id'),
      new NonTerminal('name'),
    ),
    new Sequence(
      'get',
      new NonTerminal('id'),
      new Choice(
        0,
        'max',
        'players',
        'value',
        'visible',
      ),
    ),
    'list',
    new Sequence(
      'remove',
      new NonTerminal('id'),
    ),
    new Sequence(
      'set',
      new NonTerminal('id'),
      new Choice(
        0,
        new Sequence(
          'color',
          new NonTerminal('color')
          // new Choice(0, 'blue', 'green', 'pink', 'purple', 'red', 'white', 'yellow'),
        ),
        new Sequence(
          'max',
          new NonTerminal('max'),
        ),
        new Sequence(
          'name',
          new NonTerminal('name')
        ),
        new Sequence(
          'players',
          new Optional(new NonTerminal('targets'))
        ),
        new Sequence(
          'style',
          new NonTerminal('style')
          // new Choice(0, 'notched_6', 'notched_10', 'notched_12', 'notched_20', 'progress')
        ),
        new Sequence(
          'value',
          new NonTerminal('value')
        ),
        new Sequence(
          'visible',
          new NonTerminal('visible')
        ),
      ),
    ),
  ),
)
