import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('effect'),
  new Choice(
    0,
    new Sequence(
      'clear',
      new Optional(
        new Sequence(
          new NonTerminal('targets'),
          new Optional(
            new NonTerminal('effect'),
          ),
        ),
      ),
    ),
    new Sequence(
      'give',
      new NonTerminal('targets'),
      new NonTerminal('effect'),
      new Optional(
        new Sequence(
          new NonTerminal('seconds'),
          new Optional(
            new Sequence(
              new NonTerminal('amplifier'),
              new Optional(
                new NonTerminal('hideParticles'),
              ),
            ),
          ),
        ),
      ),
    ),
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('effect'),
  new NonTerminal('target'),
  new Choice(
    0,
    'clear',
    new Sequence(
      new NonTerminal('seconds'),
      new Optional(
        new Sequence(
          new NonTerminal('amplifier'),
          new Optional(
            new NonTerminal('hideParticles'),
          ),
        ),
      ),
    ),
  ),
)
