import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('defaultgamemode'),
  new Choice(
    0,
    'survival',
    'creative',
    'adventure',
    'spectator',
  ),
)
