import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('list'),
  new Optional('uuids'),
)

export const RailBedrock = new Diagram(
  new Terminal('list'),
)
