import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('clear'),
  new Optional(
    new Sequence(
      new NonTerminal('targets'),
      new Optional(
        new Sequence(
          new NonTerminal('item'),
          new Optional(
            new NonTerminal('max count'),
          ),
        ),
      ),
    ),
  ),
);

export const RailBedrock = new Diagram(
  new Terminal('clear'),
  new Optional(
    new Sequence(
      new NonTerminal('targets'),
      new Optional(
        new Sequence(
          new NonTerminal('item name'),
          new Optional(
            new Sequence(
              new NonTerminal('data'),
              new Optional(
                new NonTerminal('max count'),
              ),
            ),
          ),
        ),
      ),
    ),
  ),
);
