import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack, HorizontalChoice } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('locatebiome'),
  new HorizontalChoice(
    new Choice(0,
      'ocean',
      'deep_ocean',
      'frozen_ocean',
      'deep_frozen_ocean',
      'cold_ocean',
      'deep_cold_ocean',
      'lukewarm_ocean',
      'deep_lukewarm_ocean',
      'warm_ocean',
      'deep_warm_ocean',
      'river',
      'frozen_river',
      'beach',
      'stone_shore',
      'snowy_beach',
      'forest',
      'wooded_hills',
      'flower_forest',
      'birch_forest',
      'birch_forest_hills',
    ),
    new Choice(0,
      'tall_birch_forest',
      'tall_birch_hills',
      'dark_forest',
      'dark_forest_hills',
      'jungle',
      'jungle_hills',
      'modified_jungle',
      'jungle_edge',
      'modified_jungle_edge',
      'bamboo_jungle',
      'bamboo_jungle_hills',
      'taiga',
      'taiga_hills',
      'taiga_mountains',
      'snowy_taiga',
      'snowy_taiga_hills',
      'snowy_taiga_mountains',
      'giant_tree_taiga',
      'giant_tree_taiga_hills',
      'giant_spruce_taiga',
    ),
    new Choice(0,
      'giant_spruce_taiga_hills',
      'mushroom_fields',
      'mushroom_field_shore',
      'swamp',
      'swamp_hills',
      'savanna',
      'savanna_plateau',
      'shattered_savanna',
      'shattered_savanna_plateau',
      'plains',
      'sunflower_plains',
      'desert',
      'desert_hills',
      'desert_lakes',
      'snowy_tundra',
      'snowy_mountains',
      'ice_spikes',
      'mountains',
      'wooded_mountains',
      'gravelly_mountains',
    ),
    new Choice(0,
      'modified_gravelly_mountains',
      'mountain_edge',
      'badlands',
      'badlands_plateau',
      'modified_badlands_plateau',
      'wooded_badlands_plateau',
      'modified_wooded_badlands_plateau',
      'eroded_badlands',
      'nether_wastes',
      'crimson_forest',
      'warped_forest',
      'soul_sand_valley',
      'basalt_deltas',
      'the_end',
      'small_end_islands',
      'end_midlands',
      'end_highlands',
      'end_barrens',
      'the_void',
    ),
  ),
)
