import React, { useState, isValidElement, cloneElement } from "react"

function RailroadSet(props) {
  const [shown, setShown] = useState(props.initial || ['main']);

  function toggleShown(s) {
    if (shown.indexOf(s) === -1) {
      setShown(shown.concat(s));
    } else {
      setShown(shown.filter(sh => sh !== s));
    }
  }

  return (
    <div>
      {props.children && React.Children.map(props.children, child => child)
        .filter(c => shown.indexOf(c.props.rep) !== -1)
        .map((child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              key: child.props.rep,
              onClickNode: toggleShown
            })
          }
        })}
    </div>
  );
}

export default RailroadSet