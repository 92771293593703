import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('enchant'),
  new NonTerminal('targets'),
  new NonTerminal('enchantment'),
  new Optional(new NonTerminal('level')),
)

export const RailBedrock = new Diagram(
  new Terminal('enchant'),
  new NonTerminal('player'),
  new Choice(
    0,
    new NonTerminal('enchantmentId'),
    new NonTerminal('enchantmentName'),
  ),
  new Optional(new NonTerminal('level')),
)
