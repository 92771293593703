import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('attribute'),
  new NonTerminal('target'),
  new NonTerminal('attribute'),
  new Choice(
    0,
    new Sequence(
      new Optional('base'),
      'get',
      new Optional(new NonTerminal('scale')),
    ),
    new Sequence(
      'base',
      'set',
      new NonTerminal('value'),
    ),
    new Sequence(
      'modifier',
      new Choice(
        0,
        new Sequence(
          'add',
          new NonTerminal('uuid'),
          new NonTerminal('name'),
          new NonTerminal('value'),
          new Choice(
            0,
            'add',
            'multiply',
            'multiply_base',
          ),
        ),
        new Sequence(
          'remove',
          new NonTerminal('uuid'),
        ),
        new Sequence(
          'value',
          'get',
          new NonTerminal('uuid'),
          new Optional(new NonTerminal('scale')),
        ),
      ),
    ),
  ),
)
