import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('give'),
  new NonTerminal('target'),
  new NonTerminal('item'),
  new Optional(new NonTerminal('count')),
)

export const RailBedrock = new Diagram(
  new Terminal('give'),
  new NonTerminal('player'),
  new NonTerminal('itemName'),
  new Optional(
    new Sequence(
      new NonTerminal('amount'),
      new Optional(
        new Sequence(
          new NonTerminal('data'),
          new Optional(
            new Sequence(
              new NonTerminal('components'),
            ),
          ),
        ),
      ),
    ),
  ),
)
