import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('forceload'),
  new Choice(
    0,
    new Sequence(
      'add',
      new NonTerminal('from'),
      new Optional(
        new NonTerminal('to'),
      ),
    ),
    new Sequence(
      'remove',
      new Choice(
        0,
        new Sequence(
          new NonTerminal('from'),
          new Optional(
            new NonTerminal('to'),
          ),
        ),
        'all',
      ),
    ),
    new Sequence(
      'query',
      new Optional(
        new NonTerminal('pos'),
      ),
    ),
  ),
)
