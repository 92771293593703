import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('advancement'),
  new Choice(
    0,
    new Terminal('grant'),
    new Terminal('revoke'),
  ),
  new NonTerminal('targets'),
  new Choice(
    0,
    new Terminal('everything'),
    new Sequence(
      new Terminal('only'),
      new NonTerminal('advancement'),
      new Optional(
        new NonTerminal('criterion')
      ),
    ),
    new Sequence(
      new Choice(
        0,
        new Terminal('from'),
        new Terminal('through'),
        new Terminal('until'),
      ),
      new NonTerminal('advancement'),
    ),
  ),
)
