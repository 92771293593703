import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('datapack'),
  new Choice(
    0,
    new Sequence(
      'disable',
      new NonTerminal('name'),
    ),
    new Sequence(
      'enable',
      new NonTerminal('name'),
      new Optional(
        new Choice(
          0,
          'first',
          'last',
          new Sequence(
            'before',
            new NonTerminal('existing'),
          ),
          new Sequence(
            'after',
            new NonTerminal('existing'),
          ),
        ),
      ),
    ),
    new Sequence(
      'list',
      new Choice(
        0,
        'available',
        'enabled',
      ),
    ),
  ),
);
