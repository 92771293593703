import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

// TODO: need some optionals
// TODO: make more readable
export const RailJava = new Diagram(
  new Terminal('clone'),
  new NonTerminal('begin'),
  new NonTerminal('end'),
  new NonTerminal('destination'),
  new Optional(
    new Sequence(
      new Choice(
        0,
        'replace',
        'masked',
        new Sequence('filtered', new NonTerminal('filter')),
      ),
      new Optional(
        new Choice(
          0,
          'force',
          'move',
          'normal',
        ),
      ),
    ),
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('clone'),
  new Group(
    new Sequence(
      new NonTerminal('x'),
      new NonTerminal('y'),
      new NonTerminal('z')
    ),
    'begin'
  ),
  new Group(
    new Sequence(
      new NonTerminal('x'),
      new NonTerminal('y'),
      new NonTerminal('z')
    ),
    'end'
  ),
  new Group(
    new Sequence(
      new NonTerminal('x'),
      new NonTerminal('y'),
      new NonTerminal('z')
    ),
    'destination'
  ),
  new Optional(
    new Sequence(
      new Group(
        new Sequence(
          new Choice(
            0,
            'replace',
            'masked',
            new Sequence('filtered', new NonTerminal('filter')),
          ),
        ),
        'mask mode',
      ),
      new Optional(
        new Sequence(
          new Group(
            new Sequence(
              new Choice(
                0,
                'force',
                'move',
                'normal',
              ),
            ),
            'clone mode',
          ),
        ),
      ),
    ),
  ),
)
