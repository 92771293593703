import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('banlist'),
  new Optional(
    new Choice(
      0,
      new NonTerminal('ips'),
      new NonTerminal('players'),
    ),
  ),
)
