import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('help'),
  new Optional(new NonTerminal('command')),
)

export const RailBedrock = new Diagram(
  new Terminal('help'),
  new Optional(
    new Choice(0,
      new NonTerminal('command'),
      new NonTerminal('page'),
    ),
  ),
)
