import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const Rail = new Diagram(
  new Terminal('title'),
  new NonTerminal('target'),
  new Choice(0,
    new Choice(0,
      'clear',
      'reset'
    ),
    new Sequence(
      new Choice(0,
        'title',
        'subtitle',
        'actionbar'
      ),
      new NonTerminal('title'),
    ),
    new Sequence(
      'times',
      new NonTerminal('fadeIn'),
      new NonTerminal('stay'),
      new NonTerminal('fadeOut'),
    ),
  ),
)
