import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('fill'),
  new NonTerminal('from'),
  new NonTerminal('to'),
  new NonTerminal('block'),
  new Optional(
    new Choice(
      0,
      new Sequence(
        'replace',
        new Optional(
          new NonTerminal('filter'),
        ),
      ),
      'destroy',
      'hollow',
      'keep',
      'outline',
    ),
  ),
)
