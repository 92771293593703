import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('time'),
  new Choice(0,
    new Sequence(
      'add',
      new NonTerminal('amount'),
    ),
    new Sequence(
      'set',
      new Choice(0,
        new NonTerminal('time'),
        'day',
        'noon',
        'sunset',
        'night',
        'midnight',
        'sunrise',
      ),
    ),
    new Sequence(
      'query',
      new Choice(0,
        'daytime',
        'gametime',
        'day',
      ),
    ),
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('time'),
  new Choice(0,
    new Sequence(
      'add',
      new NonTerminal('amount'),
    ),
    new Sequence(
      'set',
      new NonTerminal('time'),
    ),
    new Sequence(
      'query',
      new Choice(0,
        'daytime',
        'gametime',
        'day',
      ),
    ),
  ),
)
