import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack, HorizontalChoice } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('locate'),
  new HorizontalChoice(
    new Choice(0,
      'bastion_remnant',
      'buried_treasure',
      'endcity',
      'fortress',
      'mansion',
      'mineshaft',
      'monument',
      'nether_fossil',
      'ocean_ruin',
    ),
    new Choice(0,
      'pillager_outpost',
      'ruined_portal',
      'shipwreck',
      'stronghold',
      'desert_pyramid',
      'igloo',
      'jungle_pyramid',
      'swamp_hut',
      'village',
    ),
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('locate'),
  new HorizontalChoice(
    new Choice(0,
      'bastionremnant',
      'buriedtreasure',
      'endcity',
      'fortress',
      'mansion',
      'mineshaft',
      'monument',
    ),
    new Choice(0,
      'ruins',
      'pillageroutpost',
      'ruinedportal',
      'shipwreck',
      'stronghold',
      'temple',
      'village',
    ),
  ),
)
