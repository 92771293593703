import React from 'react'
import Railroad from '../components/railroad'
import RailroadSet from '../components/railroadSet'
import { Diagram, ComplexDiagram, MultipleChoice, OneOrMore, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const TargetSelectorRailroad = (
  <RailroadSet rep='target selector'>
    <Railroad rep='main' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          new Choice(
            0,
            new Sequence(new Comment('Nearest player'), '@p'),
            new Sequence(new Comment('Random player'), '@r'),
            new Sequence(new Comment('All players'), '@a'),
            new Sequence(new Comment('All entities'), '@e'),
            new Sequence(new Comment('Command executor'), '@s'),
          ),
          new Optional(
            new NonTerminal('target selector arguments'),
          ),
        ),
        'target selector'
      ),
    )} interactive={true} />

    {/* <Railroad rep='target selector arguments' diagram={new ComplexDiagram(
  new Group(
    new Sequence(
      '[',
      new OneOrMore(
        new Sequence(
          new NonTerminal('argument'),
          '=',
          new NonTerminal('value'),
        ),
        ',',
      ),
      ']',
    ),
    'target selector arguments'
  ),
)} interactive={true} /> */}

    <Railroad rep='target selector arguments' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          '[',
          new OneOrMore(
            new Choice(
              0,
              new Sequence(
                new MultipleChoice(
                  0,
                  'all',
                  new Sequence(new NonTerminal('x'), ','),
                  new Sequence(new NonTerminal('y'), ','),
                  new Sequence(new NonTerminal('z'), ','),
                ),
                new Optional(
                  new Choice(
                    0,
                    new NonTerminal('distance'),
                    new MultipleChoice(
                      0,
                      'all',
                      new Sequence(new NonTerminal('dx'), ','),
                      new Sequence(new NonTerminal('dy'), ','),
                      new Sequence(new NonTerminal('dz'), ','),
                    ),
                  ),
                ),
              ),
              new NonTerminal('scores'),
              new NonTerminal('team'),
              new Sequence(
                new NonTerminal('limit'),
                ',',
                new Optional(
                  new NonTerminal('sort'),
                ),
              ),
              new NonTerminal('level'),
              new NonTerminal('gamemode'),
              new NonTerminal('name'),
              new NonTerminal('x_rotation'),
              new NonTerminal('y_rotation'),
              new NonTerminal('type'),
              new NonTerminal('tag'),
              new NonTerminal('nbt'),
              new NonTerminal('advancements'),
              new NonTerminal('predicate'),
            ),
            ',',
          ),
          ']',
        ),
        'target selector arguments'
      ),
    )} interactive={true} />

    <Railroad rep='x' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          'x',
          '=',
          new NonTerminal('decimal number'),
        ),
        'x'
      ),
    )} interactive={true} />

    <Railroad rep='y' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          'y',
          '=',
          new NonTerminal('decimal number'),
        ),
        'y'
      ),
    )} interactive={true} />

    <Railroad rep='z' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          'z',
          '=',
          new NonTerminal('decimal number'),
        ),
        'z'
      ),
    )} interactive={true} />

    <Railroad rep='distance' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          'distance',
          '=',
          new NonTerminal('number or range'),
        ),
        'distance'
      ),
    )} interactive={true} />

    <Railroad rep='scores' diagram={new ComplexDiagram(
      new Group(
        new Sequence(
          'scores',
          '=',
          '{',
          new OneOrMore(
            new Sequence(
              new NonTerminal('score name'),
              '=',
              new NonTerminal('number or range'),
            ),
            ',',
          ),
          '}',
        ),
        'scores'
      ),
    )} interactive={true} />

    <Railroad rep='number or range' diagram={new ComplexDiagram(
      new Group(
        new Choice(
          0,
          new Sequence(
            new Comment('Exact'),
            new NonTerminal('number'),
          ),
          new Sequence(
            new Comment('Number or less'),
            new Sequence(
              '..',
              new NonTerminal('number'),
            ),
          ),
          new Sequence(
            new Comment('Number or greater'),
            new Sequence(
              new NonTerminal('number'),
              '..',
            ),
          ),
          new Sequence(
            new Comment('Between numbers (inclusive)'),
            new Sequence(
              new NonTerminal('number'),
              '..',
              new NonTerminal('number'),
            ),
          ),
        ),
        'number or range'
      ),
    )} interactive={true} />

    {/* <Railroad rep='decimal number' diagram={new ComplexDiagram(
  new Group(
    new Sequence(
      new Optional(
        new Choice(0, '-', '+'),
      ),
      new OneOrMore('0-9'),
      new Optional(
        new Sequence(
          '.',
          new OneOrMore('0-9'),
        ),
      ),
    ),
    'decimal number'
  ),
)} interactive={true} /> */}

  </RailroadSet>
)