import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('difficulty'),
  new Choice(
    0,
    'peaceful',
    'easy',
    'normal',
    'hard',
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('difficulty'),
  new Choice(
    0,
    new Choice(
      0,
      'peaceful',
      'p',
      0,
    ),
    new Choice(
      0,
      'easy',
      'e',
      1,
    ),
    new Choice(
      0,
      'normal',
      'n',
      2,
    ),
    new Choice(
      0,
      'hard',
      'h',
      3,
    ),
  ),
)
