import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('tp'),
  new Choice(0,
    new NonTerminal('target selector'),
    new Sequence(
      new NonTerminal('x'),
      new NonTerminal('y'),
      new NonTerminal('z'),
    ),
    new Sequence(
      new NonTerminal('target selector'),
      new Choice(0,
        new NonTerminal('target selector'),
        new Sequence(
          new NonTerminal('x'),
          new NonTerminal('y'),
          new NonTerminal('z'),
          new Choice(0,
            new NonTerminal('rotation'),
            new Sequence(
              'facing',
              new NonTerminal('x'),
              new NonTerminal('y'),
              new NonTerminal('z'),
            ),
            new Sequence(
              'facing',
              'entity',
              new NonTerminal('target selector'),
              new Optional(
                new Choice(0,
                  'eyes',
                  'feet',
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  ),
  new Optional(new NonTerminal('reason')),
)
