import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('weather'),
  new Choice(0,
    'clear',
    'rain',
    'thunder',
  ),
  new Optional(new NonTerminal('duration')),
)

export const RailBedrock = new Diagram(
  new Terminal('weather'),
  new Choice(0,
    new Sequence(
      new Choice(0,
        'clear',
        'rain',
        'thunder',
      ),
      new Optional(new NonTerminal('duration')),
    ),
    'query',
  ),
)
