import { Diagram, ComplexDiagram, Comment, Optional, Choice, Terminal, Group, Sequence, NonTerminal, Stack } from "@prantlf/railroad-diagrams";

export const RailJava = new Diagram(
  new Terminal('gamemode'),
  new Choice(
    0,
    'survival',
    'adventure',
    'creative',
    'spectator',
  ),
  new Optional(
    new NonTerminal('target'),
  ),
)

export const RailBedrock = new Diagram(
  new Terminal('gamemode'),
  new Choice(
    0,
    new Choice(
      0,
      'survival',
      's',
      0,
    ),
    new Choice(
      0,
      'creative',
      'c',
      1,
    ),
    new Choice(
      0,
      'adventure',
      'a',
      2,
    ),
  ),
)
